// 6 Color system
$white:              #ffffff !default;
$desert-storm:       #F8F8F7 !default;
$charcoal-grey:      #3C4142 !default;
$grey-charcoal:      #3C3D41 !default;
$oslo-grey:          #838c8d !default;
$lavender-mist:      #E5EAF4 !default;
$gallery:            #eeeeee !default;
$blue-dress:         #1476f1 !default;

$primary:            $grey-charcoal !default;
$secondary:          $blue-dress !default;

$gray-100:           $desert-storm !default;

// 130 Spacing
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 2),
    6: ($spacer * 2.5),
    7: ($spacer * 3),
    8: ($spacer * 4),
    9: ($spacer * 5),
    10: ($spacer * 9)
  ),
  $spacers
);

// 164 Body
$body-bg:                     white !default;
$body-color:                  $charcoal-grey !default;

// 221 Grid columns 

$grid-columns:                12 !default;
$grid-gutter-width:           32px !default;
$grid-row-columns:            6 !default;

// 272 Typography
$font-family-sans-serif:      'Montserrat', sans-serif, Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`

$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2.25 !default;
$h3-font-size:                $font-size-base * 2.0625 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.375 !default;
$h6-font-size:                $font-size-base * 1.25 !default;

$headings-font-weight:        700 !default;

$hr-border-color:             $gallery !default;

$hr-margin-y:                 0 !default;









// 383 Buttons + Forms
$input-btn-padding-y:         .6875rem !default;
$input-btn-padding-x:         .75rem !default;

// 410 Buttons
$btn-font-weight:             600 !default;




