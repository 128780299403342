.alphabet-list {
  .active-list-item {
    text-decoration: underline;
    font-weight: bold;
  }

  li {
    font-weight: 400;
    display: inline;
    margin: 0 5px;
    @media (max-width: 1023px) {
      display: inline-block;
    }

    a {
      &:hover {
        color: $blue-dress;
      }
    }
  }
}

@media (min-width: 992px) {
  .alphabet-list {
    display: flex;
    justify-content: space-around;
  }
}
