.loading {
    ul {
        li {
            display: inline;
            img {
                -webkit-animation:spin 2s linear infinite;
                -moz-animation:spin 2s linear infinite;
                animation:spin 2s linear infinite;
                @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
                @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
                @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
            }
        }
    }
    span {
        font-size: 26px;
        a {
            color: $blue-dress;
        }
    }
}