footer {
  background-color: white;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;

  .footer-heading {
    display: block;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: $grey-charcoal;
    margin-bottom: 16px;
  }
  .footer-logo {
    p {
      font-family: 'PT Serif', serif;
      font-size: 12px;
      color: #a4a5ab;
    }
    border-bottom: 1px solid #45464a;
  }
  .footer-menus {
    border-bottom: 1px solid #45464a;
    .footer-categories {
      ul {
        li {
          font-size: 13px;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 2px;
          a {
            color: $grey-charcoal;
          }
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
    }
    .footer-misc {
      ul {
        li {
          font-size: 13px;
          a {
            color: #838387;
          }
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .footer-social {
    border-bottom: 1px solid #45464a;
    ul {
      li {
        position: relative;
        font-size: 13px;
        padding-left: 2rem;
        a {
          color: #838387;
        }
        &:before {
          content: '';
          width: 19px;
          height: 16px;
          background-repeat: no-repeat;
          background-position: center left;
          background-size: contain;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
        }
        &.facebook {
          &:before {
            background-image: url('../../img/facebook.svg');
          }
        }
        &.twitter {
          &:before {
            background-image: url('../../img/twitter.svg');
          }
        }
        &.instagram {
          &:before {
            background-image: url('../../img/instagram.svg');
          }
        }
        &.rss {
          &:before {
            background-image: url('../../img/rss.svg');
          }
        }
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
  }
  .footer-newsletter {
    p {
      font-family: 'PT Serif', serif;
      font-size: 14px;
      line-height: 18px;
      color: #a4a5ab;
    }
    .input-group {
      input {
        background-color: $white;
      }
      .btn {
        background-color: #8bd393;
        border-color: #8bd393;
      }
    }
  }
}

.scroll-to-top {
  z-index: 100000;
  position: fixed;
  bottom: 20px;
  right: 20px;
  .icon {
    display: table;
    margin: 0 auto;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    line-height: 40px;
    background-color: #dab472;
    text-align: center;
    font-size: 20px;
    color: white;
  }
  .text {
    display: none;
  }
  div {
    &:hover {
      text-decoration: none;
    }
  }
}

.scroll-to-top.open {
  animation: bounce-in 0.35s ease-in;
}
.scroll-to-top.exit {
  animation: bounce-exit 0.35s ease-out;
}

@keyframes bounce-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes bounce-exit {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (min-width: 992px) {
  footer {
    .footer-heading {
    }
    .footer-logo {
      text-align: center;
      border-bottom: 0;
      border-right: 1px solid #d8d8d8;
    }
    .footer-menus {
      border-bottom: 0;
      border-right: 1px solid #d8d8d8;
    }
    .footer-social {
      border-bottom: 0;
      border-right: 1px solid #d8d8d8;
    }
    .footer-newsletter {
    }
  }

  .scroll-to-top {
    .text {
      display: block;
      margin-top: 10px;
      font-weight: bold;
      font-size: 14px;
    }
  }
}
