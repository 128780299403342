.settings {
  .settings-header {
    border-bottom: 1px solid #eeeeee;
    h3 {
      font-family: "PT Serif", serif;
    }
  }
  label {
    font-weight: 600;
  }
  .input-heading {
    font-weight: 700;
    font-size: 15px;
    letter-spacing: 2.73px;
    color: #838c8d;
  }
  .error-msg {
    color: red;
    text-align: left;
    margin-top: 5px;
    margin-bottom: 0px;
  }
}
