header {
  .notification {
    background-color: #3c3d41;
    p {
      font-family: 'PT Serif', serif;
      font-size: 14px;
      color: #fefefe;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      strong {
        font-size: 11px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        letter-spacing: 2px;
        margin-right: 0.25rem;
      }
    }
    a {
      display: block;
      color: #959d9e;
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;
      img {
        vertical-align: sub;
        margin-left: 10px;
      }
    }
  }
  .top-bar {
    min-height: 60px;
    background-color: white;
    .lang-switcher {
      span {
        font-size: 12px;
        color: #aaaaaa;
      }
      .dropdown-toggle {
        font-size: 11px;
        background-color: #fff;
        border-color: #3c3d41;
        color: #3c3d41;
      }
      .dropdown-menu {
        padding: 0;
        .dropdown-item {
          padding: 0.1rem 1.5rem;
          font-size: 11px;
        }
      }
    }
    .user-menu {
      li {
        display: inline;
        font-size: 10px;
        font-weight: 700;
        letter-spacing: 0.5px;
        a {
          color: #aaaaaa;
        }
        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }
    .user {
      border-left: 1px solid #eeeeee;
      .user-image {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
      .btn {
        font-size: 12px;
      }
      img {
        border-radius: 50%;
      }
      .sign-in {
        font-size: 12px;
        line-height: 28px;
        font-weight: 700;
        margin-right: 1rem !important;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .navbar {
    .navbar-brand {
      img {
        max-height: 34px;
      }
    }
    .navbar-nav {
      .nav-item {
        .nav-link {
          font-weight: 700;
          color: #3c3d41;

          &.active {
            border-bottom: 2px solid;
            width: fit-content;
          }
        }
        .dropdown-menu {
          padding: 0;
          .sub {
            background-color: #3c3d41;
            .promo {
              h5 {
                color: $white;
              }
              img {
                width: 100%;
              }
              p {
                font-family: 'PT Serif', serif;
                font-size: 14px;
                color: #888888;
              }
            }
            .categories,
            .brands {
              .menu-heading {
                display: block;
                color: $white;
                font-size: 11px;
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: 1rem;
              }
              ul {
                li {
                  font-size: 14px;
                  a {
                    color: #888888;
                  }
                  &:not(:last-child) {
                    margin-bottom: 4px;
                  }
                }
              }
            }
            .categories {
              ul {
                li {
                  display: inline-block;
                  float: left;
                  width: 33.33%;
                }
              }
            }
            .brands {
              .menu-heading {
              }
            }
          }
        }
        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  header {
    .navbar-top-border {
      // border-top: 1px solid #eee;
    }

    .navbar {
      .navbar-brand {
        img {
          max-height: none;
        }
      }
    }
  }
}

.res-header {
  @media (max-width: 375px) {
    .mr-lg-5,
    .mr-4 {
      margin-right: 0px !important;
    }
    .ml-auto {
      margin-left: 0px !important;
    }
  }
}
