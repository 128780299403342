.single-product {
  position: relative;
  &.product-overlay:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(204, 204, 204, 0.5);
  }

  .slider-for {
    line-height: 0;
    margin-bottom: 4px;
    .slick-list {
      line-height: 0;
      .slick-slide {
        line-height: 0;
      }
    }
  }
  .thumbs {
    position: relative;
    .slider-nav {
      line-height: 0;
      .slick-list {
        line-height: 0;
        margin: 0 -2px;
        .slick-slide {
          line-height: 0;
          margin: 0 2px;
        }
      }
    }
    .prev,
    .next {
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: 38px;
      height: 100%;
      background-color: rgba(60, 61, 65, 0.5);
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .prev {
      left: 0;
    }
    .next {
      right: 0;
    }
  }
  .brand {
    display: block;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #838c8d;
    margin-bottom: 10px;
    a {
      color: #838c8d;
    }
  }
  .name {
    margin-bottom: 22px;
  }
  .pricing {
    margin-bottom: 30px;
    .discount {
      background-color: #cd9450;
      color: white;
      font-weight: 700;
      font-size: 10px;
      letter-spacing: 1.1px;
      text-transform: uppercase;
      padding: 3px 5px 2px 5px;
      border-radius: 2px;
    }
    .new-price {
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 0.67px;
      color: #3c3d41;
    }
    .old-price {
      font-size: 15px;
      letter-spacing: 0.67px;
      color: #838d8f;
      text-decoration: line-through;
    }
  }
  .nav {
    margin-bottom: 20px;
    .nav-item {
      .nav-link {
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 2px;
        color: #838c8e;
        text-transform: uppercase;
        border-radius: 20px;
        border-bottom: 1px solid transparent;
        border-radius: 0;
        padding: 5px 0;
        &.active {
          background-color: transparent;
          border-bottom: 1px solid #3c3d41;
          color: #3c3d41;
        }
      }
      &:not(:last-child) {
        margin-right: 30px;
      }
    }
  }
  .tab-content {
    p {
      font-family: 'PT Serif', serif;
      font-size: 16px;
      line-height: 1.31;
      color: #777777;
    }
    ul {
      margin-bottom: 0;
      li {
        position: relative;
        font-size: 12px;
        color: #959ea0;
        padding-left: 1rem;
        &:before {
          content: '';
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: #959ea0;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
        }
        &:not(:last-child) {
          margin-bottom: 3px;
        }
      }
    }
  }
  .colors {
    .subtitle {
      font-size: 12px;
      letter-spacing: 0.4px;
      color: #3c3d41;
      min-width: 80px;
    }
    ul {
      li {
        display: inline-block;
        float: left;
        margin: 0.25rem;
        a {
          position: relative;
          display: block;
          width: 32px;
          height: 32px;
          border-radius: 32px;
          &:hover {
            text-decoration: none;
            &:before {
              content: '';
              width: 15px;
              height: 13px;
              background-image: url('../../img/color_select.svg');
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
        &.active {
          a {
            &:before {
              content: '';
              width: 15px;
              height: 13px;
              background-image: url('../../img/color_select.svg');
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
        &:not(:last-child) {
          margin-right: 6px;
        }
      }
    }
  }
  .size {
    .subtitle {
      font-size: 12px;
      letter-spacing: 0.4px;
      color: #3c3d41;
      min-width: 80px;
    }
    ul {
      li {
        display: inline-block;
        float: left;
        margin: 0.25rem;
        a {
          position: relative;
          display: block;
          width: 32px;
          height: 32px;
          border-radius: 32px;
          background-color: #eeeeee;
          font-size: 11px;
          line-height: 32px;
          text-align: center;
          //&:hover {
          //  text-decoration: none;
          //  background-color: #838d8f;
          //  color: white;
          //}
        }
        &.active {
          a {
            background-color: #838d8f;
            color: white;
          }
        }
        &:not(:last-child) {
          margin-right: 6px;
        }
      }
    }
    .size-guide {
      font-size: 12px;
      letter-spacing: 0.4px;
      color: #3c3d41;
      a {
        text-decoration: underline;
      }
    }
  }
  .add-to-cart {
    .btn {
      position: relative;
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 2px;
      color: #ffffff;
      padding: 14px 20px 14px 40px;
      border-radius: 30px;
      margin-right: 20px;
      img {
        position: absolute;
        left: 12px;
        bottom: 15px;
      }
    }
    .fav-add {
      border: solid 1px #eeeeee;
      background-color: #eeeeee;
      width: 45px;
      height: 45px;
      line-height: 43px;
      text-align: center;
      border-radius: 45px;
      svg {
        max-width: 21px;
      }
    }
    .fav-added {
      border: solid 1px #e6492d;
      background-color: #eeeeee;
      width: 45px;
      height: 45px;
      line-height: 43px;
      text-align: center;
      border-radius: 45px;
      svg {
        max-width: 21px;
      }
    }
    .fav-add,
    .fav-added {
      cursor: pointer;
    }

    .seen-disabled,
    .seen-enabled {
      cursor: pointer;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #eeeeee;
      width: 45px;
      height: 45px;
      line-height: 43px;
      text-align: center;
      border-radius: 45px;
      margin-left: 0.25rem !important;
      img {
        width: 26px;
      }
    }

    .seen-enabled {
      border: solid 1px #000000;
    }
  }
  .share {
    ul {
      li {
        display: inline-block;
        span {
          font-size: 12px;
          letter-spacing: 0.4px;
          color: #3c3d41;
        }
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}

.product-pagination {
  background-color: white;
  a {
    span {
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 2px;
      color: #838c8e;
    }
  }
}

.related-items {
  position: relative;
  .slider-title {
    display: block;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #3c3d41;
    padding: 18px 0;
    margin-bottom: 18px;
    //border-bottom: solid 1px #eeeeee;
  }
  .slider-tip {
    display: block;
    font-size: 11px;
    font-weight: lighter;
    letter-spacing: 2px;
    color: #3c3d41;
    padding: 18px 0;
    margin-bottom: 18px;
  }
  .related-slider {
    .slick-list {
      margin: 0 -15px;
      .slick-slide {
        margin: 0 15px;
      }
    }
  }
  .prev,
  .next {
    cursor: pointer;
    position: absolute;
  }
  .prev {
    top: 12px;
    right: 45px;
  }
  .next {
    top: 12px;
    right: 15px;
  }
}
