.home {
  p {
    font-family: 'PT Serif', serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.29px;
    color: #666666;
    margin-bottom: 0;
  }
}

.homepage {
  &.homepage-banner {
    padding: 5rem 0;
    background-image: url(../../img/homepagebanner.jpg);
    background-size: cover;

    .btn {
        border-radius: 1.65rem;
        font-size: 1.15rem;
        padding: 12px 24px;
    }
  }

  &.homepage-icons {
    padding: 3rem 0;
    background-image: linear-gradient(
      to bottom,
      rgba(250, 251, 251, 0),
      #f8fbff
    );

    .inline-logos {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 30px;
      grid-row-gap: 30px;

      .logo {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    p {
      font-family: 'PT Serif', serif;
    }
  }

  &.homepage-steps {
    background-color: white;
    padding: 3rem 0;

    h2,
    h6 {
      font-family: 'PT Serif', serif;
    }

    img {
      max-height: 200px;
      margin-bottom: 2rem;
    }
  }

  &.homepage-cta {
    background-color: white;
    padding: 3rem 0;

    a {
      text-decoration: underline;
      color: #1476f1;
    }
  }
}

@media (min-width: 992px) {
  .homepage {
    &.homepage-banner {
      padding: 10rem 0;
    }

    &.homepage-icons {
      padding: 5rem 0;

      .inline-logos {
        grid-template-columns: repeat(5, 1fr);
      }
    }

    &.homepage-steps {
      padding: 5rem 0;
    }
  }
}
