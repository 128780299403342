.brand-filter-product {
  .custom-control {
    padding-left: 1.7rem !important;
  }
}

.toggle-switch-wrapper {
  font-size: 14px;
  font-weight: 500;
  color: #3c3d41;
}

.custom-switch-input:not(:checked)~.custom-control-label::before {
  background-color: #3C3D41 !important;
}

.custom-switch-input:not(:checked)~.custom-control-label::after {
  background-color: #FFFFFF !important;
}