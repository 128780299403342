.signup-login {
  .signup-login-bg {
    background-image: url("../../img/signup-login.png");
    background-size: cover;
    min-height: 200px;
  }
  .signup-login-content {
    font-size: 14px;
    .btn-login {
      position: relative;
      font-size: 16px;
      padding-top: 11px;
      padding-bottom: 11px;
      img {
        max-height: 32px;
        position: absolute;
        top: 50%;
        left: 11px;
        transform: translate(0, -50%);
      }
    }
    .btn-google {
      background-color: $gallery;
    }
    .btn-facebook {
      background-color: #3b5998;
      color: $white;
    }
    label {
      font-weight: 600;
    }
    .form-check-label {
      color: #838d8f;
      a {
        text-decoration: underline;
        color: #838d8f;
      }
    }
    .log-in {
      color: #5a7184;
      a {
        color: #1476f1;
        font-weight: 700;
      }
    }
    .error-msg {
      color: red;
      text-align: left;
      margin-top: 5px;
      margin-bottom: 0px;
    }
  }
  .kep-login-facebook {
    padding: 0;
    width: 100%;
    text-transform: unset;
    border-radius: 0.3rem !important;
    font-family: "Montserrat", sans-serif;
    border: 0;
  }
}

@media (max-width: 991px) {
  .signup-fixed {
    display: none;
  }
}

@media (min-width: 992px) {
  .signup-fixed {
    position: absolute;
    z-index: 999;
    background: transparent;
    left: 0;
    right: 0;
    top: 55px;
  }
}
@media (min-width: 768px) {
  .signup-login {
    .signup-login-content {
      min-height: calc(100vh - 140px);

      &.verify {
        height: calc(100vh - 380px);
        min-height: 550px;
      }
    }

    .welcome {
      height: 100vh;

      &.verify {
        height: calc(100vh - 508px);
        min-height: 429px;
      }
    }
  }
}
