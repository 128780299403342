.contact-email, .social-media {
  a {
    margin-left: 10px;
    font-weight: 600;
    text-decoration: underline;
  }

  img {
    width: 48px;
    height: 48px;
    margin-right: 50px;
  }
}