.products-actions {
  background-color: white;
  position: relative;
  border-bottom: 1px solid #eee;
  .title {
    font-weight: 700;
    font-size: 14px;
  }
  .item-count {
    font-size: 14px;
    font-weight: 500;
    color: #3c3d41;
  }
  .filters {
    .btn-group {
      .dropdown-toggle {
        position: relative;
        padding-right: 1.5rem;
        border-radius: 1.5rem;
        min-width: 142px;
        &:after {
          content: '';
          background-image: url('../../img/dropdown-down.png');
          background-repeat: no-repeat;
          border: 0;
          width: 5px;
          height: 4px;
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translate(0, -50%);
        }
      }
      &.show {
        .dropdown-toggle {
          &:after {
            background-image: url('../../img/dropdown-up.png');
          }
        }
      }
    }

    span {
      font-size: 14px;
      color: #9b9b9c;
    }
    .dropdown-toggle {
      font-size: 11px;
      background-color: #3c3d41;
      border-color: #3c3d41;
    }
    .dropdown-menu {
      padding: 0;
      z-index: 100 !important;
      .dropdown-item {
        padding: 0.1rem 1.5rem;
        font-size: 11px;
      }
    }
  }

  .mobile-filter {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 19px;
  }
}

@media (min-width: 992px) {
  .products-actions {
    .border-lg-right {
      border-right: 1px solid #eeeeee;
    }
  }
}

@media (min-width: 1200px) {
}

.products-side-option {
  background: white;
}