.product-img-slider {
  .slick-dots {
    display: flex !important;
    justify-content: center;
  }

  .slick-arrow {
    background-color: rgba(204, 204, 204, 0.5);
    z-index: 1;
  }

  .alice-carousel__wrapper {
    .thumbs {
      li {
        width: 16.66% !important;
      }
    }
  }
  .page-slider-dots-6 {
    li {
      width: 87px !important;
      margin: 0 2px;
      &:first-child {
        margin-left: 0px !important;
      }
      &:last-child {
        margin-right: 0px !important;
      }
    }
  }
  .page-slide-less-6 {
    ul {
      display: flex;
      justify-content: center;
    }
    li {
      width: 87px !important;
      margin: 0 2px;
      &:first-child {
        margin-left: 0px !important;
      }
      &:last-child {
        margin-right: 0px !important;
      }
    }
  }
}

@media (max-width: 347px) {
  .res-buy-at {
    img {
      bottom: 20px !important;
      left: 25px !important;
    }
  }
}

.related-product-slider {
  .slick-track {
    width: 10000px !important;
  }
  .slick-slide {
    @media (min-width: 1200px) {
      width: 255px !important;
      margin: 0px 8px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      width: 210px !important;
      margin: 0px 8px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      width: 210px !important;
      margin: 0 12px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      width: 239px !important;
      margin: 0 12px;
    }
    @media (max-width: 575px) {
      padding: 0 12px;
    }
  }
  .slick-list {
    @media (max-width: 991px) {
      margin: 0px -15px !important;
    }
  }
}

.fade:not(.show) {
  opacity: 0;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;

  &.fade {
    transition: opacity 0.15s linear;

    .modal-dialog {
      transition: transform 0.3s ease-out;
      transform: translate(0, -50px);
    }
  }

  &.show {
    .modal-dialog {
      transform: none;
    }
  }

  .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;

    .modal-content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      pointer-events: auto;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 0.3rem;
      outline: 0;

      .modal-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 1rem 1rem;
        border-bottom: 1px solid #dee2e6;
        border-top-left-radius: calc(0.3rem - 1px);
        border-top-right-radius: calc(0.3rem - 1px);

        .modal-title {
          margin-bottom: 0;
          line-height: 1.5;
        }

        .close {
          padding: 1rem 1rem;
          margin: -1rem -1rem -1rem auto;
        }
      }

      .modal-body {
        position: relative;
        flex: 1 1 auto;
        padding: 1rem;
      }
    }
  }
}

#sizingModal {
  .modal-body {
    p {
      font-size: 15px;
    }

    .name {
      display: block;
      font-weight: bold;
      color: #000;
    }

    .table-responsive {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      font-size: 14px;

      .size-type {
        display: block;
        font-size: 15px;
      }

      .table {
        width: 100%;
        margin-bottom: 1rem;
        color: #3c4142;
        font-size: 14px;
        border-collapse: collapse;

        thead {
          background-color: #e9ecef;
        }

        td {
          padding: 0.75rem;
          vertical-align: top;
          border-top: 1px solid #dee2e6;
        }
      }
    }
  }
}

@media (min-width: 576px) {
  .modal {
    .modal-dialog {
      max-width: 600px;
      margin: 1.75rem auto;
    }
  }

  .modal.signup-dialog {
    .modal-dialog {
      max-width: 440px;
      margin: 1.75rem auto;

      p {
        font-size: 20px;
      }
    }
  }
}

.additional-links {
  .additional-link {
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: capitalize;
    color: #838c8d;
    &:hover {
      text-decoration: underline;
    }
  }
}