.how-it-works {
    h3 {
        font-family: 'PT Serif', serif;
        line-height: 1.33;
        letter-spacing: 0.2px;
    }
    .steps {
        h6 {
            line-height: 1.2;
            letter-spacing: 0.2px;
        }
        p {
            line-height: 1.75;
            color: #777777;
        }
    }
}

.how-it-works-banner {
    position: relative;
    p {
        font-family: 'PT Serif', serif;
        font-size: 25px;
        font-weight: bold;
        line-height: 1.24;
        letter-spacing: 0.36px;
        color: #3c3d41;
    }
}

@media (min-width: 992px) {
    .how-it-works {
        h3 {
    
        }
        .steps {
            h6 {
                min-height: 48px;
            }
        }
    }
    .how-it-works-banner {
        .inner {
            position: absolute;
            top: 50%;
            left: 38%;
            transform: translate(-50%, -50%);
            z-index: 99;
            max-width: 600px;
        }
    }
}