.settings .custom-control.custom-checkbox, .questionnaire .custom-control.custom-checkbox {
    cursor: pointer;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
    border: 1px solid $lavender-mist;
    background-color: $white;
    margin: 0.5rem 0;
    .custom-control-input {
        cursor: pointer;
        left: 1rem;
        width: 100%;
    }
    .custom-control-label {
        cursor: pointer;
        padding-left: 2rem;
        &:before {
            left: 0;
        }
        &:after {
            left: 0;
        }
    }
    &.custom-checkbox-checked {
        border: 1px solid $blue-dress;
        label {
            color: $blue-dress;
            font-weight: 600;
            &:before {
                border-color: $blue-dress;
                background-color: $blue-dress;
            }
        }
    }
    &:hover {
        border: 1px solid $blue-dress;
        label {
            color: $blue-dress;
            font-weight: 600;
        }
    }
    label.subcategory, label.brand, label.store, label.size, label.price  {
        font-weight: normal;
    }
}