.font-weight-medium {
  font-weight: 500;
}

body {
  @media (max-width: 300px) {
    width: 300px;
    .responsive-top-menu-left,
    .responsive-top-menu-right {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }
}

.responsive-top-menu-left,
.responsive-top-menu-right {
  @media (max-width: 360px) {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    justify-content: flex-start !important;
  }
}

button.btn.btn-secondary.btn-sm.dropdown-toggle {
  @media (max-width: 360px) {
    min-width: 120px;
  }
}
