.questionnaire {
  .input-heading {
    font-weight: 700;
    font-size: 15px;
    letter-spacing: 2.73px;
    color: #838c8d;
  }
  .nav-pills {
    .nav-item {
      .nav-link {
        border: 1px solid #3c3d41;
        font-weight: 700;
        cursor: pointer;
      }
      &:not(:last-child) {
        a {
          margin-right: 1rem;
        }
      }
    }
  }
  &.questionnaire_5 {
    background-image: url("../../img/banner2.png");
    background-size: cover;
    background-position: center center;
    color: $primary;
    h3 {
      font-family: "PT Serif", serif;
    }
    p {
      font-size: 18px;
      font-weight: 500;
      font-style: italic;
    }
  }
  .btn-left {
    position: relative;
    img {
      position: absolute;
      left: 30px;
      top: 17px;
    }
  }
  .btn-right {
    position: relative;
    img {
      position: absolute;
      right: 30px;
      top: 17px;
    }
  }
}

@media (max-width: 768px) {
  .questionnaire {
    &.questionnaire_3 {
      .nav-pills {
        .nav-item {
          width: 100%;
          .nav-link {
            width: 100%;
          }
          &:not(:last-child) {
            a {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
}
