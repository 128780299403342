.product-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 32px;
}

.product {
  &.product-overlay:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(204, 204, 204, 0.5);
  }
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  height: 100%;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    .thumb {
      .overlay {
        opacity: 1;
      }
    }
  }
  .thumb {
    position: relative;
    margin-bottom: 36px;
    img {
      width: 100%;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    .overlay {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      width: 100%;
      transform: translate(0%, -50%);
      opacity: 0;
      text-align: center;
      .shop {
        margin-right: 0.25rem;
      }
      img {
        max-width: 40px;
      }
    }
    .discount {
      position: absolute;
      bottom: -28px;
      left: 0;
      background-color: #cd9450;
      color: white;
      font-weight: 700;
      font-size: 10px;
      letter-spacing: 1.1px;
      text-transform: uppercase;
      display: table;
      padding: 3px 5px 2px 5px;
      border-radius: 2px;
      margin-left: 1px;
    }

    .actions {
      position: absolute;
      right: 0;
      bottom: -32px;
      display: flex;
      margin-right: 1px;
      .favourite {
        span {
          display: block;
          width: 25px;
          height: 25px;
          line-height: 25px;
          border-radius: 25px;
          text-align: center;
          border: solid 1px #eeeeee;
          background-color: #eeeeee;
          background-image: url(../../img/fav-add.png);
          background-size: 13px;
          background-repeat: no-repeat;
          background-position: center center;
        }

        &.active span {
          border: solid 1px #e6492d;
          background-image: url(../../img/fav-added.png);
        }
      }

      .watchlist {
        cursor: pointer;
        z-index: 99;

        span {
          display: block;
          width: 25px;
          height: 25px;
          line-height: 25px;
          border-radius: 25px;
          text-align: center;
          border: solid 1px #eeeeee;
          background-color: #eeeeee;
          background-image: url(../../img/watchlist-add.png);
          background-size: 14px;
          background-repeat: no-repeat;
          background-position: center center;
        }

        &.active span {
          border: solid 1px #000000;
          background-image: url(../../img/watchlist-added.png);
        }
      }
    }
  }
  .brand {
    display: block;
    font-family: 'PT Serif', serif;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    font-style: italic;
    margin-bottom: 5px;
    padding: 0 1px;
  }
  .name {
    display: block;
    font-size: 13px;
    line-height: 18px;
    // margin-bottom: 10px;
    padding: 0 1px;
  }
  .price {
    display: block;
    font-size: 12px;
    line-height: 22px;
    font-weight: 700;
    color: #1e1f1e;
    padding: 0 1px;
  }
  .old-price {
    display: block;
    text-align: right;
    font-size: 13px;
    line-height: 22px;
    color: #838d8f;
    text-decoration: line-through;
    padding: 0 1px;
  }

  &.small-product {
    .name {
      font-size: 12px;
    }
    .price {
      font-size: 12px;
    }
    .old-price {
      font-size: 12px;
    }
  }
}

.settings {
  .product {
    &.product-overlay:before {
      background-color: unset;
    }
  }
}

.banner {
  background-image: url('../../img/banner.png');
}

.react-tabs {
  .react-tabs__tab-list {
    border-bottom: 0px;
    .react-tabs__tab--selected {
      border: white;
      border-bottom: 1px solid;
    }
    li {
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 2px;
      color: #838c8e;
      text-transform: uppercase;
      border-radius: 20px;
      border-bottom: 1px solid transparent;
      border-radius: 0;
      padding: 6px 0px;
      margin-right: 30px;
      background-color: transparent;
      color: #3c3d41;
    }
  }
}

.paginations {
  margin: 15px 0 0 0;
  ul {
    justify-content: center;
    li {
      display: inline-block;
      &.page {
        font-size: 16px;
        font-weight: 600;
        width: 29px;
        height: 29px;
        line-height: 29px;
        text-align: center;
        a {
          display: block;
        }
      }
      &.prev,
      &.next {
        a {
          display: block;
        }
      }
      &.next {
        img {
          -moz-transform: scale(-1, -1);
          -o-transform: scale(-1, -1);
          -webkit-transform: scale(-1, -1);
          transform: scale(-1, -1);
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .paginations {
    ul {
      justify-content: flex-end;
      li {
        margin: 0 3px;
      }
    }
  }
}

@media (min-width: 768px) {
  .product-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 992px) {
  .product-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .paginations {
    ul {
      justify-content: flex-end;
      li {
        margin: 0 3px;
      }
    }
  }
}

@media (min-width: 1269px) {
  .product-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}
