.sidebar {
  background-color: white;
  border-radius: 4px;
  &.sidebar-sticky {
    position: sticky;
    top: 0px;
  }

  .input-group {
    .btn {
      border-top: 1px solid #ced4da;
      border-right: 1px solid #ced4da;
      border-bottom: 1px solid #ced4da;
    }
  }
  .sidebar-search {
    padding: 16px 0px;
  }
  .filterToggle {
    display: block;
    padding: 16px 0px !important;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-decoration: none;
    border-bottom: 1px solid #eeeeee;
  }
  .card {
    border-radius: 0;
    border: 0;
  }
  .sidebar-menu {
    &.my-profile {
      > li {
        > a {
          padding: 16px 0px !important;
        }
      }
    }
    > li {
      position: relative;
      > a {
        display: block;
        padding: 16px 0px;
        font-size: 11px;
        font-weight: bold;
        letter-spacing: 2px;
        text-transform: uppercase;
        &.active {
          color: $blue-dress;
        }
        &:hover {
          text-decoration: none;
        }
      }
      &.has-dropdown {
        &:before {
          content: '';
          position: absolute;
          top: 21px;
          right: 0px;
          width: 9px;
          height: 7px;
          background-image: url(../../img/arrow-down.svg);
          background-repeat: no-repeat;
        }
        &.show {
          &:before {
            transform: scale(-1, -1);
          }
        }
      }
      &:not(:last-child) {
        > a {
          border-bottom: 1px solid #eeeeee;
        }
      }
    }
    > li {
      .inner {
        display: none;
        padding: 16px 0px;
        .menu-links {
          li {
            a {
              display: block;
              font-size: 13px;
              line-height: 13px;
              &:hover {
                color: $blue-dress;
                font-weight: 700;
              }
            }
            &:not(:last-child) {
              margin-bottom: 8px;
            }
            &.active {
              a {
                color: $blue-dress;
                font-weight: 700;
                text-decoration: underline;
              }
            }
          }
        }
        .menu-checkboxes {
          .autocomplete {
            .autocomplete-items {
              position: absolute;
              border: 1px solid #d4d4d4;
              border-bottom: none;
              border-top: none;
              z-index: 999;
              top: 100%;
              left: 0;
              right: 0;

              div {
                padding: 5px 5px;
                font-size: 12px;
                cursor: pointer;
                background-color: #fff;
                border-bottom: 1px solid #d4d4d4;
              }
            }
          }

          .checkboxes {
            overflow-y: auto;
            max-height: 100px;
            .custom-checkbox {
              .custom-control-label {
                font-family: 'PT Serif', serif;
                font-size: 13px;
                line-height: 22px;
              }
            }
          }
        }
        .range-slider {
          label {
            font-weight: 600;
            font-size: 12px;
          }
        }
        .menu-sizes {
          display: flex;
          flex-wrap: wrap;
          li {
            flex: 0 0 16.6666666667%;
            margin-bottom: 8px;
            a {
              display: block;
              width: 32px;
              height: 32px;
              border-radius: 32px;
              background-color: #eeeeee;
              font-size: 11px;
              line-height: 32px;
              text-align: center;
              &:hover {
                text-decoration: none;
                background-color: #838d8f;
                color: white;
              }
            }
            &.active {
              a {
                background-color: #838d8f;
                color: white;
              }
            }
          }
        }
        .menu-colors {
          display: flex;
          flex-wrap: wrap;
          li {
            flex: 0 0 16.6666666667%;
            margin-bottom: 8px;
            a {
              position: relative;
              display: block;
              width: 32px;
              height: 32px;
              border-radius: 32px;
              &:hover {
                text-decoration: none;
                &:before {
                  content: '';
                  width: 15px;
                  height: 13px;
                  background-image: url('../../img/color_select.svg');
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }
            }
            &.active {
              a {
                &:before {
                  content: '';
                  width: 15px;
                  height: 13px;
                  background-image: url('../../img/color_select.svg');
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }
            }
          }
        }
      }
    }
  }
}

.left-right-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 32px;
  .left-part {

  }
  .right-part {

  }
}

.settings {
  .left-right-grid {
    grid-column-gap: 32px;
  }
}

@media (min-width: 768px) {
  .sidebar {
    .sidebar-menu {
      > li {
        .inner {
          .menu-sizes {
            li {
              flex: 0 0 10%;
            }
          }
          .menu-colors {
            li {
              flex: 0 0 10%;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .sidebar {
    .sidebar-menu {
      > li {
        .inner {
          .menu-sizes {
            li {
              flex: 0 0 20%;
            }
          }
          .menu-colors {
            li {
              flex: 0 0 20%;
            }
          }
        }
      }
    }
  }
  .left-right-grid {
    grid-template-columns: repeat(12, 1fr);
    .left-part {
        grid-area: 1 / 1 / 2 / 4;
    }
    .right-part {
        grid-area: 1 / 4 / 2 / 13;
    }
  }
}

@media (min-width: 1269px) {
  .left-right-grid {
      grid-template-columns: repeat(24, 1fr);
      .left-part {
          grid-area: 1 / 1 / 2 / 6;
      }
      .right-part {
          grid-area: 1 / 6 / 2 / 25;
      }
  }
}
